// http://ionicframework.com/docs/theming/
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
// @import '@ionic/angular/css/typography.css';
// @import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/padding.css';
// @import '@ionic/angular/css/float-elements.css';
// @import '@ionic/angular/css/text-alignment.css';
// @import '@ionic/angular/css/text-transformation.css';
// @import '@ionic/angular/css/flex-utils.css';

// Configuration Variables
//
// Possible to override via the `theme/overrides.scss`-file (set before/during build)
:root {
  // Fonts
  //
  // Fonts - Theme (named fonts):
  --hia_font_open-sans: 'Open Sans', Arial, 'Helvetica Neue', sans-serif;
  --hia_font_montserrat: 'montserrat_webfont', Tahoma, 'Gill Sans', sans-serif;

  // Fonts - Content-text:
  --hia_content_font-family: var(
    --env_hia_content_font-family,
    var(--hia_font_open-sans)
  );
  --hia_content_font-size: var(--env_hia_content_font-size, 16px);

  // Fonts - Headings:
  --hia_alt_font-family: var(
    --env_hia_alt_font-family,
    var(--hia_font_montserrat)
  );
  --hia_heading_font-size: var(--env_hia_heading_font-size, 16px);

  // Colors
  //
  // Colors - Theme (named colors):
  --hia_color_light-beige: #faf5e5;
  --hia_color_dark-green: #2f5f6e;
  --hia_color_dark-navy: #011e41;
  --hia_color_white: #ffffff;

  --hia_style_shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.1);

  // Colors - Functions
  //
  // Colors - Function: Base (the main body of content)
  --hia_base_text: var(--env_hia_base_text, var(--hia_color_dark-navy));
  --hia_base_fill: var(--env_hia_base_fill, var(--hia_color_light-beige));

  // Colors - Function: Alt (alternative color-scheme, used for Footer)
  --hia_alt_text: var(--env_hia_alt_text, var(--hia_color_white));
  --hia_alt_fill: var(--env_hia_alt_fill, var(--hia_color_dark-green));

  // Colors - Function: Level (slightly raised, used for CTAs etc.
  --hia_level_text: var(--env_hia_level_text, var(--hia_color_dark-navy));
  --hia_level_fill: var(--env_hia_level_fill, var(--hia_color_white));

  --hia_level_shadow: var(--env_hia_level_shadow, var(--hia_style_shadow));
}

// Baseline Print styles
@import '_print.scss';

// Custom Fonts
@font-face {
  font-family: 'Open Sans';
  src:
    local('Open Sans'),
    url('assets/fonts/open-sans/OpenSans-Regular-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: optional;
}
@font-face {
  font-family: 'Open Sans';
  src:
    local('Open Sans Bold'),
    url('assets/fonts/open-sans/OpenSans-Bold-webfont.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: 'montserrat_webfont';
  src:
    local('Montserrat Bold'),
    url('assets/fonts/montserrat/montserrat-bold-webfont.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: optional;
}

// Enable Dynamic Font Scaling
// See: https://ionicframework.com/docs/layout/dynamic-font-scaling
// From: '~@ionic/angular/css/typography.css';
html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);

  font-family: var(--ion-font-family);
}
// Enable Dynamic Type (iOS only)
@supports (-webkit-touch-callout: none) {
  html {
    font: var(--ion-dynamic-font, 16px var(--ion-font-family));
  }
}

//
@media print {
  :root {
    --print-text: #000;
    --print-background: #fff;

    --color: var(--print-text);
    --ion-text-color: var(--print-text);

    --background-color: var(--print-background);
    --ion-color-base: var(--print-background);
    --ion-background-color: var(--print-background);
  }

  *,
  *::before,
  *::after {
    background: var(--print-background) none !important;
    color: var(--print-text);
  }

  // Flatten the 'app'-shell to a regular page
  ion-content::part(scroll),
  html,
  body,
  ion-router-outlet,
  .ion-page {
    position: relative;
    overflow-y: visible;
    contain: none;

    background: var(--print-background) none;
  }
}

//
// Baseline / Normalizations
//
hr {
  border-top: 1px dashed currentColor;
}

a {
  color: inherit;
  -webkit-touch-callout: initial; // Enable "tap-and-hold" on links on mobile/touch/iOS
}

details summary {
  cursor: pointer;
}

hr {
  border-top: 1px dashed currentColor;
}

// 'Page'-width
.layout--container {
  width: 100%;
  margin-inline: auto;
  max-width: 500px;

  &.layout--container_wide {
    max-width: 1024px;
  }
}

ion-content::part(scroll),
.layout--stretch-height {
  display: flex;
  flex-flow: column nowrap;
}
// Must be used IN a flex-parent (like .layout--stretch-height)
.layout--stick-to-end {
  margin-block-start: auto;
}

// Workaround for touch-screen laptops
// See: https://github.com/ionic-team/ionic-framework/issues/19942#issuecomment-621944260
// See: https://github.com/ionic-team/ionic-framework/blob/4.11.x/core/src/components/app/app.scss
html.plt-mobile ion-app {
  user-select: auto;
}

// Prevent scrolling-area from becommnig too small
@media screen and (orientation: landscape) and (max-height: 30em) {
  ion-header {
    max-height: 20vh;
    overflow: auto;
  }
}

// Improve rendering performance (possibly) by lowering priority of images:
img[decoding='async'] {
  content-visibility: auto;
}

//
// Layout Utilities:
//
.no-margin-bottom {
  margin-bottom: 0 !important;
}

.layout--float-end {
  float: right;
  float: inline-end;

  [dir='rtl'] & {
    float: left;
    float: inline-end;
  }
}

.list-flat {
  padding-inline-start: 0;

  > li {
    list-style: none;
  }
}
.list-inline {
  > li {
    display: inline;
  }
}

.tile-grid {
  --column-gap: 0.1875rem;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
  margin-inline-start: calc(-2 * var(--column-gap));
  margin-inline-end: calc(-2 * var(--column-gap));

  .tile-grid--item {
    padding-inline-start: var(--column-gap);
    padding-inline-end: var(--column-gap);
    margin-block: var(--column-gap);
    min-width: 150px; // To always fit 2 items for narrow viewports
  }

  &.tile-grid_base-2 {
    .tile-grid--item {
      flex: 1 0 50%;
    }
  }
  &.tile-grid_base-3 {
    .tile-grid--item {
      flex: 1 0 33%;
    }
  }
}

// Floating Action Button (bottom-end)
.floating-action-area {
  position: fixed;

  @media print {
    display: none;
  }

  &.is_in-page {
    position: relative;
  }
  &.is_bottom-end {
    inset-inline-end: 1.33rem;
    bottom: 4rem;
  }
}

//
// Misc. Style Utilities
//
.focus--minimal:focus-visible {
  outline-width: 1px;
  outline-style: dashed;
}
@keyframes focus-fade-out {
  0% {
    outline: 1px dashed currentColor;
    outline-offset: 2px;
  }
  100% {
    outline-color: transparent;
  }
}
.focus--fade-out:focus-visible {
  animation: focus-fade-out 1s 0s 1 forwards;
}

//
// Text Style Utilities:
//
.text-style--center {
  text-align: center !important;
}
.text-style--end {
  text-align: end !important;
}
.text-style--use-newlines {
  white-space: pre-wrap;
}
.text-style--alt {
  font-family: var(--hia_alt_font-family);
  font-weight: bold;
  letter-spacing: 0.08em;

  [dir='rtl'] & {
    letter-spacing: 0;
  }
}
.text-style--size-08 {
  font-size: 0.875rem;
}
.text-style--size-1 {
  font-size: 1rem;
}
.text-style--size-2 {
  font-size: 1.25rem;
}
.text-style--hide-overflow_5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 1.4;
  max-height: calc(5 * 1.4em);
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-style--inline-link:visited,
.text-style--inline-link:link,
.text-style--inline-link {
  color: inherit;

  &.is_minimal {
    text-decoration-style: dotted;
  }
}

.text-area--level {
  font-family: var(--hia_content_font-family);
  color: var(--hia_level_text);
  background-color: var(--hia_level_fill);
}

.text-area--alt {
  color: var(--hia_alt_text);
  background-color: var(--hia_alt_fill);
}

//
// Components
//

// Component: Floating Box
//
// Styling-only helper.
.floating-box {
  border-radius: 0.25rem;
  box-shadow: var(--hia_level_shadow);
}

// Component: Loading Text skeleton/placeholder
@keyframes shine {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}
.loading-text {
  --background-rgb: 184, 184, 184;

  display: inline-block;
  width: 100%;
  line-height: 1;
  background: linear-gradient(
    to right,
    rgba(var(--background-rgb), 0.1) 8%,
    rgba(var(--background-rgb), 0.4) 18%,
    rgba(var(--background-rgb), 0.1) 33%
  );
  background-size: 1000px 100%;
  animation-name: shine;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &::before {
    content: ' ';
    display: inline-block;
  }
}

// Component: Action (CTA/Button)
.action {
  --action--color: var(--hia_level_text);
  --action--color-active: var(--hia_level_text);
  --action--background-color: var(--hia_level_fill);
  --action--background-color-active: #efefef;

  display: block;
  width: 100%;
  padding: 0.5em;

  border-radius: 0.25rem;
  text-decoration: none;
  box-shadow: var(--hia_level_shadow);

  @media print {
    --action--color: #333;
    --action--color-active: #000;
    --action--background-color: #fff;
    --action--background-color-active: #fff;

    border: 0.05em solid #ccc;
    font-weight: bold;
    box-shadow: none;
  }

  &,
  &:link,
  &:visited {
    color: var(--action--color);
    background-color: var(--action--background-color);
  }

  &:hover,
  &:focus {
    color: var(--action--color-active);
    background-color: var(--action--background-color-active);
    outline-color: transparent;
    outline-offset: 0.1875rem;
    outline-width: 0.125rem;
    outline-style: solid;
  }
  &:focus {
    outline-color: var(--action--color-active);
  }

  &.is_outline {
    padding: 0.5em 1em;
    font-weight: bold;
    border: 0.0625rem solid var(--action--color);
  }

  &.is_inline {
    display: inline-block;
    width: auto;

    .icon {
      vertical-align: middle;
    }
  }

  &.is_round {
    line-height: 1;
    border-radius: 50%;
    min-width: 2rem;
    min-height: 2rem;

    &.is_inline {
      border-radius: 1rem;
    }
  }

  &.is_flat {
    box-shadow: none;
  }
}

// Component: Q&A Set
.q-a-set {
  &[open] > .q-a-set--toggle {
    box-shadow: none; // Override .action styles
  }

  .q-a-set--content {
    padding-inline-start: 1.6rem; // Allow room for marker as padding

    *:first-of-type {
      margin-top: 0;
    }
  }
}

// Component: Sheet Button
.sheet--button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 3em;
  text-align: center;
}

// Component: Icon
.icon {
  &.is_small {
    width: 0.8125em;
    height: 0.8125em;
  }
  &.is_medium {
    width: 1em;
    height: 1em;
  }
  &.is_inline-start {
    margin-inline-end: 0.25em;
  }
  &.is_inline-end {
    margin-inline-start: 0.25em;
  }
}

// Component: List Item
//
// Layout helper similar to "media object/island"
.list-item {
  display: flex;

  .list-item--icon {
    flex: 0 0 auto;
    margin-inline-end: 0.75rem;
  }
  .list-item--content {
    flex: 1 1 100%;
  }
}

// Component: Logo Badge
.logo-badge {
  display: flex;
  width: 6.5rem;
  height: 6.5rem;
  padding: 0.5rem;
  background-color: rgba(244, 247, 250, 1);
  border-radius: 0.25rem;

  .logo-badge--image {
    width: 100%;
    height: 100%;
  }
}

// Component: Contact-option Button
.contact-button {
  display: block;
  line-height: 1;

  .contact-button--link {
    display: inline-block;
    overflow: hidden;
  }

  .contact-button--logo {
    display: inline-block;
    vertical-align: middle;
    max-width: 48px;
    max-height: 48px;
    aspect-ratio: 1 / 1;
  }
}

.contact-button + .contact-button {
  margin-top: 1rem;
}

// Component: Dismissable Container
//
// Details-element as 'sticky' notification
.dismissible-container {
  position: relative;

  // Use summary-toggle as dismiss-button, so hide-when-closed:
  &:not([open]) {
    display: none;
  }

  .dismissible-container--close {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;

    &::-webkit-details-marker,
    &::marker {
      display: none;
    }
  }
}

/**
  Markdown generated HTML overrides
*/
[markdown],
.markdown {
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }

  h6 + p,
  h5 + p,
  h4 + p,
  h3 + p,
  & > ol:first-child,
  & > ul:first-child,
  & > p:first-child {
    margin-top: 0;
  }

  ol,
  ul {
    padding-inline-start: 1em;
  }
  table {
    width: 100%;
  }
  th,
  td {
    padding: 0.25em;
  }

  @media print {
    a[href]:not([rel~='x-plain-url'])::after {
      content: ' (' attr(href) ')';
    }
  }

  // Utility layout/style classes
  .d-block {
    display: block;
  }

  .d-flex {
    display: flex;
  }

  .d-flex-justify-between {
    justify-content: space-between;
  }

  .d-flex-align-end {
    align-items: flex-end;
  }

  .d-center {
    text-align: center;
  }
}

/**
  Overrides for Google Translate (in-page)
*/
// Make room for the Google Translate bar
body[style*='margin-top: 56px;'] {
  margin-top: 0 !important;
  background-position-y: 56px;

  ion-app {
    top: 56px;
  }
}
// Hide own language-switcher when Google's is active
body[style*='margin-top: 0px;'],
body[style*='margin-top: 56px;'] {
  .language-switcher {
    display: none;
  }
}
